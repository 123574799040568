export class DataSet {
	private data: { [key: string]: number } = {};
	private name: string = '';

	constructor(name: string, data: { [key: string]: number }) {
		this.data = data;
		this.name = name;
	}

	public get(key: string): number {
		return this.data[key];
	}

	public getAll(): { [key: string]: number } {
		return this.data;
	}

	public each(callback: (key: string, value: number) => void): void {
		for (const key in this.data) {
			if (this.data.hasOwnProperty(key)) {
				callback(key, this.data[key]);
			}
		}
	}

	public getName(): string {
		return this.name;
	}
}
