import { DataSet } from './dataset';

export type Layer = {
	labels: {
		x: string;
		y: string;
	};
	points: { x: number; y: number }[];
};

export function createLayer({ x, y }: { x: DataSet; y: DataSet }): Layer {
	const label: Layer['labels'] = {
		x: x.getName(),
		y: y.getName(),
	};

	const points: Layer['points'] = [];

	x.each((key, xValue) => {
		const yValue = y.get(key);
		if (yValue !== undefined && yValue !== null) {
			points.push({ x: xValue, y: yValue });
		}
	});

	return {
		labels: label,
		points: points,
	};
}
