import * as THREE from 'three';
import { OrbitControls } from 'three-stdlib';

export default function (): { scene: THREE.Scene; animate: () => void } {
	const scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(
		75,
		window.innerWidth / window.innerHeight,
		0.1,
		1000
	);

	const renderer = new THREE.WebGLRenderer({
		antialias: false,
	});
	renderer.setPixelRatio(window.devicePixelRatio || 2);
	scene.background = new THREE.Color(0xffffff);
	renderer.setSize(window.innerWidth, window.innerHeight);
	document.body.appendChild(renderer.domElement);

	const controls = new OrbitControls(camera, renderer.domElement);
	camera.position.set(0, 0, 100);
	controls.update();

	const animate = function () {
		requestAnimationFrame(animate);

		renderer.render(scene, camera);
	};

	return {
		scene,
		animate,
	};
}
