import * as THREE from 'three';
import * as THREEAR from 'threear';

export default async function (): Promise<{
	scene: THREE.Group;
	animate: () => void;
}> {
	const deviceId = await getMediaDeviceId();

	const renderer = new THREE.WebGLRenderer({
		antialias: true,
		alpha: true,
	});
	renderer.setClearColor(new THREE.Color('lightgrey'), 0);
	renderer.setPixelRatio(window.devicePixelRatio || 2);
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.domElement.style.position = 'absolute';
	renderer.domElement.style.top = '0px';
	renderer.domElement.style.left = '0px';
	document.body.appendChild(renderer.domElement);

	var scene = new THREE.Scene();
	var camera = new THREE.Camera();
	// camera.position.x = 10;
	scene.add(camera);

	var markerGroup = new THREE.Group();
	scene.add(markerGroup);

	let source: THREEAR.Source;
	if (deviceId) {
		source = new THREEAR.Source({ renderer, camera, deviceId });
	} else source = new THREEAR.Source({ renderer, camera });

	function animate() {
		THREEAR.initialize({ source: source }).then((controller) => {
			var patternMarker = new THREEAR.PatternMarker({
				patternUrl: './patt.hiro',
				// @ts-ignore applyMatrix has been renamed to applyMatrix4
				markerObject: markerGroup,
			});

			controller.trackMarker(patternMarker);

			// run the rendering loop
			requestAnimationFrame(function animate(nowMsec) {
				// keep looping
				requestAnimationFrame(animate);
				// call each update function
				controller.update(source.domElement);
				renderer.render(scene, camera);
			});
		});
	}

	return {
		scene: markerGroup,
		animate,
	};
}

async function getMediaDeviceId(): Promise<string | null> {
	if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
		console.log('enumerateDevices() not supported.');
		return null;
	}

	const mediaDevices = (await navigator.mediaDevices.enumerateDevices()).filter(
		(d) => d.kind === 'videoinput'
	);

	if (mediaDevices.length > 0) {
		const select = document.createElement('select');
		select.innerHTML = '';
		const option = document.createElement('option');
		option.value = '';
		option.text = 'Select a camera';
		select.appendChild(option);
		let count = 1;
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === 'videoinput') {
				const option = document.createElement('option');
				option.value = mediaDevice.deviceId;
				const label = mediaDevice.label || `Camera ${count++}`;
				const textNode = document.createTextNode(label);
				option.appendChild(textNode);
				select.appendChild(option);
			}
		});
		select.style.position = 'absolute';
		select.style.left = '0px';
		select.style.bottom = '20px';
		select.style.width = '90%';
		select.style.margin = '0px 5%';
		select.style.height = '10vh';
		select.style.zIndex = '99';

		document.body.appendChild(select);

		return new Promise((resolve) => {
			select.addEventListener('change', () => {
				document.body.removeChild(select);
				resolve(select.value);
			});
		});
	} else {
		return null;
	}
}
