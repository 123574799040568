import TextSprite from '@seregpie/three.text-sprite';
import * as THREE from 'three';
import { latitude, longitude } from './datasets/coordinates';
import { costOfLiving } from './datasets/costOfLiving';
import { internet } from './datasets/internet';
import { population } from './datasets/population';
import { temperature } from './datasets/temperature';
import { createLayer, Layer } from './layer';
import arScene from './scenes/arScene';
import devScene from './scenes/devScene';

const simpleMode = window.location.search.includes('simple');

const scale = simpleMode ? 50 : 4;
const distance = simpleMode ? 25 : 2;

async function main() {
	// const { animate, scene } = devScene();
	const { animate, scene } = simpleMode ? devScene() : await arScene();

	const layers: Layer[] = [
		createLayer({ x: latitude, y: longitude }),
		createLayer({ x: internet, y: temperature }),
		createLayer({ x: population, y: costOfLiving }),
	];

	let zero = new TextSprite({
		alignment: 'center',
		color: '#000',
		fontFamily: 'sans-serif',
		fontSize: scale * 0.03,
		text: '0',
	});
	zero.position.set(-(scale / 2), 0, scale / 2);
	scene.add(zero);

	layers.forEach((layer, layerIndex) => {
		const startY = layerIndex * distance;
		const endY = (layerIndex + 1) * distance;

		// ------------------------------------------------------------
		// debug rectangle
		// const rectangle = new THREE.Mesh(
		// 	new THREE.PlaneGeometry(scale, scale),
		// 	new THREE.MeshBasicMaterial({
		// 		color: '#000',
		// 		transparent: true,
		// 		opacity: 0.5,
		// 	})
		// );
		// rectangle.position.set(0, startY, 0);
		// rectangle.rotation.x = -Math.PI / 2;
		// scene.add(rectangle);
		// --------------------------------------------------------------------------------

		let labelX = new TextSprite({
			alignment: 'center',
			color: '#000',
			fontFamily: 'sans-serif',
			fontSize: scale * 0.03,
			text: layer.labels.x,
		});
		labelX.position.set(0, startY, scale / 2);
		scene.add(labelX);

		let labelY = new TextSprite({
			alignment: 'center',
			color: '#000',
			fontFamily: 'sans-serif',
			fontSize: scale * 0.03,
			text: layer.labels.y,
		});
		labelY.position.set(-(scale / 2), startY, 0);
		scene.add(labelY);

		if (layerIndex < layers.length - 1)
			layer.points.forEach((p1, pointIndex) => {
				const layer2 = layers[layerIndex + 1];
				const p2 = layer2.points[pointIndex];
				if (!p2) return;
				const curve = new THREE.CubicBezierCurve3(
					new THREE.Vector3(p1.x * scale, startY, -p1.y * scale),
					new THREE.Vector3(
						(p1.x * scale) / 2,
						startY + distance / 2,
						(-p1.y * scale) / 2
					),
					new THREE.Vector3(
						(p2.x * scale) / 2,
						endY - distance / 2,
						(-p2.y * scale) / 2
					),
					new THREE.Vector3(p2.x * scale, endY, -p2.y * scale)
				);

				const points = curve.getPoints(50);
				const curveGeometry = new THREE.BufferGeometry().setFromPoints(points);

				const tubeGeometry = new THREE.TubeBufferGeometry(
					curve,
					20,
					scale * 0.001,
					8,
					false
				);

				const curveMaterial = new THREE.LineBasicMaterial({
					color: 0xff0000,
				});

				// Create the final object to add to the scene
				const curveObject = new THREE.Line(curveGeometry, curveMaterial);
				scene.add(curveObject);
			});
	});

	animate();
}

main();
